<template>
  <div class="form">
    <p class="text-3xl font-bold mb-8">Edit Profile</p>
    <div class="mb-4">
      <TextField type="text" :borderEnabled="true" placeholder="Input your name" label="Full Name" v-model="payloads.name" />
    </div>
    <div class="mb-4">
      <TextField type="text" :disabled="true" :borderEnabled="true" placeholder="Input your email" label="Email" v-model="payloads.email" />
    </div>
    <div class="mb-4">
      <TextField type="text" :borderEnabled="true" placeholder="Input your phone number" label="Phone Number" v-model="payloads.phoneNumber" :error="error" />
    </div>
    <div class="px-1 mb-8">
      <label class="text-xs pl-3 text-left block mb-1">Description</label>
      <textarea class="h-52 text-sm border-grey-field border resize-none px-3 py-3 focus:outline-none focus:ring focus:ring-tosca w-full rounded-lg" v-model="payloads.description" />
    </div>
    <div class="text-center">
      <Button buttonText="Save" @action="save()" :disabled="!studentId" />
    </div>
  </div>
</template>
<script>
export default {
  components: {
    TextField: () => import(/* webpackChunkName: "textfield" */ '@/components/forms/TextField'),
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button')
  },
  props: {
    student: {
      type: Object,
      default: () => {}
    },
    fullName: {
      type: String
    },
    studentId: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    payloads: '',
    error: ''
  }),
  mounted() {
    this.payloads = Object.assign({}, this.student)
    if (!this.payloads.name) {
      this.payloads.name = this.fullName
    }
  },
  methods: {
    save() {
      this.$emit('save', this.payloads)
    }
  }
}
</script>
